
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    date: String,
    author: String,
  },
})
export default class Date extends Vue {
  public date!: string;
  public author!: string;
}
