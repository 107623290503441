export class DataRes<T> {
  public data!: T;
  public status: DataResStatus = DataResStatus.None;
  public messages: string[] = [];

  public constructor(model: Partial<DataRes<T>>) {
    Object.assign(this, model);
  }
}

export enum DataResStatus {
  None = 0,
  Ok = 1,
  Error = 2,
  LoginRequired = 3,
  NotFound = 4,
}
