
import { Options, Vue } from "vue-class-component";

const storageName = "okcookie";

@Options({})
export default class CookieConsent extends Vue {
  hasAccepted = true;

  mounted(): void {
    const stored = localStorage.getItem(storageName);
    if (stored !== "y") {
      this.hasAccepted = false;
    }
  }

  accept(): void {
    localStorage.setItem(storageName, "y");
    this.hasAccepted = true;
  }
}
