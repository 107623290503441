
import { State, useStore } from "@/store";
import { Options, setup, Vue } from "vue-class-component";
import { Store } from "vuex";
import { Toast } from "@/models/Toast";

@Options({})
export default class ToastComponent extends Vue {
  store = setup<Store<State>>(() => useStore());

  get toasts(): Toast[] {
    return this.store.getters.toasts;
  }

  remove(id: string): void {
    this.store.dispatch({
      type: "toastRemove",
      toastId: id,
    });
  }
}
