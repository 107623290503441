import { Toast, ToastType } from "@/models/Toast";
import { store } from "@/store";

export const toastAdd = (messageIn: string, title = "Hey there!", type = ToastType.Success): void => {
  const message = messageIn.split(/[\r\n]+/);
  store.dispatch({
    type: "toastAdd",
    toast: new Toast({
      title,
      message,
      type,
    }),
  });
};
