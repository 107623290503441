
import { REQUEST_END, REQUEST_START } from "@/services/BaseService";
import { Options, Vue } from "vue-class-component";
import { store } from "@/store";

@Options({})
export default class LoadingIndicator extends Vue {
  public progress = 0;
  public waiting = 0;
  public timer: any;
  public timerFinal: any;

  mounted(): void {
    this.start = this.start.bind(this);
    this.end = this.end.bind(this);
    window.addEventListener(REQUEST_START, () => {
      this.start();
    });
    window.addEventListener(REQUEST_END, () => {
      this.end();
    });
  }

  get isLoading(): boolean {
    return store.getters.isLoading;
  }

  get percentage(): string {
    return `${this.progress}%`;
  }

  public start(): void {
    this.waiting++;
    if (this.progress >= 100) {
      this.progress = 0;
    }
    this.progressTimer();
  }

  public end(): void {
    clearTimeout(this.timer);
    clearTimeout(this.timerFinal);

    if (this.waiting > 0) {
      this.waiting--;
    }

    if (this.waiting === 0) {
      this.progress = 100;
      this.timerFinal = setTimeout(() => {
        this.progress = 0;
      }, 500);
    }
  }

  public progressTimer(): void {
    clearTimeout(this.timer);
    clearTimeout(this.timerFinal);
    const increase = Math.floor((100 - this.progress) / 2);
    this.progress = this.progress + increase > 100 ? 100 : this.progress + increase;
    this.timer = setTimeout(() => this.progressTimer(), 100);
  }
}
