export class Toast {
  public id = "";
  public title = "";
  public message: Array<string> = [""];
  public type: ToastType = ToastType.None;
  public hideAfter = 4000;
  public constructor(model?: Partial<Toast>) {
    this.id = "_" + Math.random().toString(36).substr(2, 9);
    if (model) {
      Object.assign(this, model);
    }
  }
}

export enum ToastType {
  None = "none",
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}
